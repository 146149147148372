.tree_root
  &__container
    display: grid
    grid-template-rows: 1fr 290px

    overflow: hidden
    height: 100%

.tree
  &__container
    width: 100%
    overflow: auto


.drop-line
  background: inherit

  height: 4px

  &--hover
    height: 4px
    background: rgba(0, 0, 0, 0.3)


.tree-control-panel
  &__container
    height: 85%
    width: 100%

    overflow-y: auto
    display: flex
    align-items: stretch
    flex-flow: column nowrap

