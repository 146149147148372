.info-no-border {
	border: none;
    color: #97B3BB;
    font-weight: normal;
    margin: 0;
}

.info-label {
	padding: 5px 20px;
	border-radius: 8px;
	background: #f4f8fa; /* Old browsers */
	background: -moz-linear-gradient(top, #f4f8fa 0%, #eff9fe 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #f4f8fa 0%,#eff9fe 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #f4f8fa 0%,#eff9fe 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f8fa', endColorstr='#eff9fe',GradientType=0 ); /* IE6-9 */
}

.info-label.warning {
	background: #fff9f0; /* Old browsers */
	background: -moz-linear-gradient(top, #fff9f0 0%, #fff6f0 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #fff9f0 0%,#fff6f0 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #fff9f0 0%,#fff6f0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff9f0', endColorstr='#fff6f0',GradientType=0 ); /* IE6-9 */
}

.info-label.fail {
	background: #fff0f5; /* Old browsers */
	background: -moz-linear-gradient(top, #fff0f5 0%, #fff0f0 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #fff0f5 0%,#fff0f0 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #fff0f5 0%, #fff0f0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff0f5', endColorstr='#fff0f0',GradientType=0 ); /* IE6-9 */
}

.info-label.transparent {
	background: transparent;
}

.info-label p {
	font-size: 1rem;
	margin: 0;
	text-align: center;
	color: #ABC1C7;
	font-weight: normal;
}

.info-label.warning p {
	color: #EF7931;
}

.info-label.fail p {
	color: #dc3545;
}

.info-label.transparent p {
	color: #0E3944;	
}