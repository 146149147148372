.catalog_root
  &__container

    width: 100%
    height: calc(var(--vh, 1vh) * 100)
    display: grid
    grid-template-rows: 47px 1fr
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1)

  &__editor
    overflow: hidden
    height: 100%

  &__body
    padding: 20px 10px
    height: 100%
    overflow: hidden

.tab
  &--n0
    display: grid
    grid-template-columns: 230px 3fr
    grid-gap: 10px
    height: 100%
    overflow: hidden
