.root_counters
  display: grid
  grid-template-columns: 300px 1fr
  grid-template-rows: 1fr
  height: 100%
  overflow: hidden
  box-sizing: border-box
  padding-bottom: 35px

.counter-edit
  &__container
    overflow-y: auto
    height: 100%

    display: flex
    flex-flow: column nowrap
    align-items: stretch
    align-content: stretch


  &__static-fields

    display: grid
    grid-template-columns: 1fr
    grid-template-rows: 50px 50px
    grid-gap: 10px
    padding: 8px 16px 16px
    margin-bottom: 10px

.expansion-panel-container
  width: 100%
  display: grid
  grid-template-columns: 1fr
  grid-gap: 10px