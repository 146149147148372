.inner-cage {
    padding: 10px 5px;
    height: calc(var(--vh, 1vh) * 100 - 104px);
    overflow-y: auto;
    overflow-x: hidden;
}

.separator-text {
    font-size: 24px;
    font-weight: normal;
    margin: 30px 0;
}

.filters {
    display: flex;
}

.filterWidth {
    flex-basis: 300px;
}

.action-plan-empty {

    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.action-plan-empty > div {
    width: 90%;
    max-width: 600px;
    padding: 25px 0px;
}

.action-plan-empty img {
    opacity: .25;
    max-width: 300px;
}

.action-plan-empty .info-label {
    width: 100%;
}

.action-plan-empty .title {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: normal;
    text-align: center;
    text-transform: lowercase;
}

.action-plan-empty .title:first-letter {
    text-transform: capitalize;
}

.action-plan-empty .sub-title {
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 22px;
    color: #04A1C7;
    text-align: center;
}

@media screen and (min-width: 769px) {
    .action-plan-empty {
        align-items: center;
    }

    /*	.action-plan-empty > div {
            margin-top: -60px;
      }*/
    .inner-cage {
        padding: 10px 20px;
    }
}