body {
  margin: 0;
  padding: 0;
 
}

.togglable {
  font-weight: bold;
  cursor: pointer;
  color: #868e96;
}

.untogglable {
  margin-left: 16px;
}

.togglable-down::before,
.togglable-up::before {
  /* font-size: 8px; */
  margin-right: 0.5em;
}

.togglable-down::before {
  content: "▼";
  display: inline-block;
  font-size: 0.7rem;
}

.togglable-up::before {
  content: "▶";
  display: inline-block;
  font-size: 0.7rem;
}


.tree-node-selected {
  background-color: #ffe6b0;
  border: 1px #ffb951 solid;
  opacity: 0.8;
  width: fit-content;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.searchtext-wrapper {
  display: flex;
  white-space: pre;
}

.clear-search {
  margin-left: auto;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
#workingArea {
  flex: 1;
  overflow-y: scroll;
}

.explorer-cage, .search-cage {
  height: 100%;
}

.noDecoration {
  list-style-type: none;
  padding-left: 15px;
}


.clear-search:hover {
  color: rgba(3, 160, 199, 0.8);
  text-decoration: underline;
}

.activityName {
  width: 100%;
}

div .caption {
  font-weight: bold;
}

div .blue {
  color: #1ba1c5;
  font-weight: bold;
}

div .blue.selected {
  color: #ffffff;
  font-weight: bold;
}

.flexColumn {
  flex-direction: row;
  align-items: flex-end;
}

@media screen and (max-width: 1500px) {
  .flexColumn {
    flex-direction: column;
    align-items: initial;
  }
}

#product-body-card-view {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#product-body-list-view {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#product-body-list-view .products {
  flex-direction: column;
}

.products {
  width: 98%;
  margin: 0 auto;
  height: 100%;
  min-height: 250px;
}

.icon {
  margin-left: 1em;
  margin-right: 1em;
  vertical-align: middle;
  cursor: pointer;
  display: inline-flex;
}

.icon:first-child {
  margin-left: 2em;
}

.icon:last-child {
  margin-right: 2em;
}

.side-bar {
  width: 48px;
  min-width: 48px;
  overflow: hidden;
  transition: all .25s ease;
  background: #06262f;
  /* Old browsers */
  background: -moz-linear-gradient(45deg, #06262f 0%, #05364b 82%, #05364a 99%, #05364a 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #06262f 0%, #05364b 82%, #05364a 99%, #05364a 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #06262f 0%, #05364b 82%, #05364a 99%, #05364a 99%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#06262f', endColorstr='#05364a', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  position: sticky;
  top: 0;

}

.side-bar.expand {
  width: 280px;
  min-width: 280px;
  overflow-y: auto;
}


.search {
  display: inline-block;
  padding-right: 1em;
}
.search > input {
  height: 3em;
  width: 250px;
  padding-left: 1em;
  border: solid 2px #D8DCE3;
  color: #D8DCE3;
  border-radius: 5px;
  background-image: url('./image/search-icon.png');
  background-position: 95% center;
  background-repeat: no-repeat;
}


.search > input:focus {
  outline: none;
  color: #404040;
  border-color: #14A1C6;
}

.head-part {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  transition: all .25s ease;
  height: 58px;
}

.expand .head-part {
  justify-content: space-between;
  padding: 15px 20px;
}

.side-bar-settings {
  background: unset !important;
}

.toggle-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 28px;
  width: 28px;
  padding: 0;
  outline: none;
  transform: rotate(540deg);
  transition: all .25s ease;

}

.expand .toggle-btn {
  transform: rotate(0deg);
}

.bottom-part {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
  overflow-y: auto !important;
}

.bottom-part .vertical-group {
  opacity: 0;
  visibility: hidden;
  transition: all .25s ease;
}

.expand .bottom-part .vertical-group {
  opacity: 1;
  visibility: visible;
}

.block-content {
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  opacity: 0;
  transition: all .25s ease;
  background-color: rgb(64, 64, 64);
  z-index: 1103;
}

.search > input:focus::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

.product-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0em;
  height: auto;
  width: 100%;
}

.product-header h1 {
  display: inline-block;
}

.product-header-buttons {
  display: flex;
  align-items: center;
}

.product-header-message {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background-color: #F2F3F5;
  align-items: center;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.product-header-action-buttons {
  display: flex;
}

.product-header-message .button-accept,
.product-header-message .button-add {
  width: 150px;
  margin-left: 10px;
}



@media only screen and (max-width: 1024px) {
  .product-header {
    padding: 1em 1em;
    flex-wrap: wrap;
  }

  .product-header h1 {
    flex-basis: 100%;
  }

  .product-header-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 1em;
    flex-basis: 100%;
  }

  .product-header-message {
    display: block;
  }

  .product-header-action-buttons {
    margin-top: 1em;
  }

  .product-header-message .button-accept {
    margin: 0 auto;
  }

  .search > input {
    width: 100%;
  }

  .icon:last-child {
    margin-right: 1em;
  }
}

@media only screen and (max-width: 512px) {

}





.outer-group.current.settingtype {
  border-left: 0px !important;
}

.outer-group.settingtype span {
  color: #235a69;
}

.outer-cage2 {
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 100%;
}

.settingsTable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.settingsTable th {
  padding: 0 0 0 25px;
  text-align: left;
  color: #afbdc8;
}

.infoTableHeader th {
  padding: 0 0 0 25px;
  text-align: left;
  font-size: 0.7rem;
}

.settingsTable tr {
  text-align: left;
  height: 2.5rem;
  border-bottom: solid 1px #D8DCE3;
}

.settingsTable .tbody tr:hover {
  background-color: #D8DCE3;
  cursor: pointer;
}

.settingsTable .tbody tr:hover td {
  background-color: #D8DCE3;
}

.settingsTable td {
  padding: 0 0 0 25px;
  text-align: left;
  font-size: 0.8rem;
}

.trQuickEdit {
  width: 35px;
}

.selectedTableRow {
  background-color: #D8DCE3;
}

.notSelectedTableRow {
  background-color: #ffffff;
}

.centerCage {
  display: flex;
  overflow-y: auto;
  height: 100%;
  position: sticky;
  bottom: 0px;
}

.tableCage {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  padding: 2px;
  position: sticky;
  bottom: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  /*/height: -webkit-fill-available;*/
}

.tableButtons {
  flex-basis: 50px;
  border-top: 1px solid #d8dce3;
  display: flex;
  padding: 10px;
  bottom: 0px;
  background-color: #FFFFFF;
}

.applist-wrapper {
  padding-left: 1rem;
}

.delete-button {
  padding: 0 20px 0 0;
  color: rgb(226, 53, 53);
  font-size: 0.7rem;
  font-weight: bold;
  cursor: pointer;
  text-align: right;
  text-transform: uppercase;
}

.add-button {
  align-self: center;
  padding: 0 20px 0 0;
  color: #1ba1c5;
  font-size: 0.7rem;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
}

.sales-image-container {
  -webkit-box-shadow: -17px 3px 0px -13px rgba(216, 220, 227, 1);
  -moz-box-shadow: -17px 3px 0px -13px rgba(216, 220, 227, 1);
  box-shadow: -17px 3px 0px -13px rgba(216, 220, 227, 1);
}

.UserEditor__MainContent {
  overflow-y: auto;
  box-sizing: border-box;
  padding: 5px 20px;
}

.overViewTree {
  flex: 1;
}

.add-cover-background {
  background-color: #80808099;
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.add-cover-background input {
  display: none;
}

.add-cover-button {
  position: relative;
  float: right;
  top: 45px;
  background-color: transparent !important;
}

.overViewWrapper {
  display: flex;
}

.overViewTreeWrapper {
  flex: 1;
}

.project.overViewTreeWrapper div a {
  text-decoration: none;
  transition: all .25s ease;
}

.project.overViewTreeWrapper:hover div a {
  color: #0056b3;
  text-decoration: underline;
  margin-left: 10px;
}

.overViewTreeProgressWrapper,
.overViewTreeProgressPanelWrapper {
  flex: 0.33;
}

.overviewStatusPanel {
  padding-left: 20px;
}

.overViewTreeSection {
  color: #abc1c7;
  font-size: 1rem;
  padding: 10px 0px 10px 20px;
}

.overViewTreeProgress {
  flex: 1 1;
  color: #abc1c7;
  padding: 10px 0px 10px 20px;
  display: flex;
  font-size: 0.875rem;
}

.overViewProgressPanel {
  flex: 1;
  height: 100%;
}

.overViewProgressPanelSelectors {
  margin: 20px 0px 0px 0px;
}

.overviewInfoHidable {
  flex: 0.4;
  height: 100%;
  display: var(--RRinfoDisplay);
}

.overviewInfoHidableSelector {
  margin: 10px 25px 0px 0px;
}

.overViewProgressPanelCaption {
  flex: 1;
  color: #abc1c7;
  padding: 10px 0px 10px 20px;
  display: flex;
  font-size: 0.875rem;
}

.overViewProgressPanelKostyl {
  max-width: 350px;
}

.riskRowTree {
  list-style-type: none;
  padding-left: 20px;
}

.acProgres {
  padding: 30px 0px;
}

.rrProgres {
  display: flex;
  max-height: 21px;
  font-size: 0.75rem;
  align-items: flex-end;
}

.rrProgres div.progrText {
  display: inline-flex;
  color: #abc1c7;
  font-weight: 600;
  margin-bottom: -4px;
  margin-right: 1em;
}

.riskRowTree li {
  color: black;
  font-size: 0.9rem;
  font-weight: normal;
  display: flex;
  align-items: center;
}

.riskRowTree li.caption {
  color: black;
  font-size: 1rem;
  font-weight: 600;
}

.riskRowTree a {
  color: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  text-decoration: none;
}

.riskRowTree a :visited {
  color: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  text-decoration: none;
}

.riskRowTree li.blue {
  color: #1ba1c5;
  font-weight: 600;
  font-size: 0.875rem;
}

.barGray {
  width: 2px;
  display: inline-block;
  align-self: flex-end;
  background-color: #e1eaee;
}

.barYellow {
  width: 2px;
  display: inline-block;
  align-self: flex-end;
  background-color: #f0c770;
}

.barRed {
  width: 2px;
  display: inline-block;
  align-self: flex-end;
  background-color: #fb9281;
}

.rrProgres div.progrGray {
  flex-basis: 75%;
  height: 2px;
  background-color: #eff3f6;
}

.rrProgres div.completedGray {
  flex: 10;
  height: 2px;
  margin: 10px 10px 0px 5px;
  background-color: #eff3f6;
  display: flex;
}

.rrProgres div.progrLoader {
  height: 2px;
  width: 0px;
  background-color: #eff3f6;
  float: right;
  animation-name: progresAnime;
  animation-duration: 1s;
  animation-delay: 0.1s;
}

.rrProgres div.progrGreen {
  height: 2px;
  background-color: #4cc58e;
  max-width: 100%;
}

.rrProgres div.progrBlue {
  flex: 10;
  height: 2px;
  margin: 10px 10px 0px 5px;
  background-color: #1ba1c5;
}

.rrRiskText {
  color: #abc1c7;
  font-weight: 600;
  width: 30px;
  text-align: center;
  margin-bottom: -5px;
}

.rrRiskScore {
  flex: 1;
  color: #abc1c7;
  font-weight: bold;
  padding: 6px 8px 0px 8px;
  height: 21px;
}

@keyframes progresAnime {
  0% {
    width: 100%;
  }
  10% {
    width: 90%;
  }
  20% {
    width: 80%;
  }
  30% {
    width: 70%;
  }
  40% {
    width: 60%;
  }
  50% {
    width: 50%;
  }
  60% {
    width: 40%;
  }
  70% {
    width: 30%;
  }
  80% {
    width: 20%;
  }
  90% {
    width: 10%;
  }
  100% {
    width: 0;
  }
}

@keyframes completedAnime {
  0% {
    flex-basis: 100px;
    background-color: #eff3f6;
  }
  10% {
    flex-basis: 90px;
    background-color: #eff3f6;
  }
  20% {
    flex-basis: 80px;
    background-color: #eff3f6;
  }
  30% {
    flex-basis: 70px;
    background-color: #eff3f6;
  }
  40% {
    flex-basis: 60px;
    background-color: #eff3f6;
  }
  50% {
    flex-basis: 50px;
    background-color: #eff3f6;
  }
  60% {
    flex-basis: 40px;
    background-color: #eff3f6;
  }
  70% {
    flex-basis: 30px;
    background-color: #eff3f6;
  }
  80% {
    flex-basis: 20px;
    background-color: #eff3f6;
  }
  90% {
    flex-basis: 10px;
    background-color: #eff3f6;
  }
  100% {
    flex-basis: 5px;
    background-color: #eff3f6;
  }
}

.summaryNode {
  margin-top: 1rem;
  height: 25px;
}

.rrRiskBars {
  height: 21px;
  flex-basis: 75%;
  display: flex;
  box-shadow: inset 0 -2px 0 0 #eff3f6;
}

.textButtonBlue {
  color: #1ba1c5;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s;
  border: none;
  background: none;
}

.textButtonBlue:hover {
  transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  animation: txtButBluAnime 1s infinite;
}

.textButtonRed {
  color: #ef4c31;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s;
  border: none;
  background: none;
}

.textButtonRed:hover {
  transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  animation: txtButRedAnime 1s infinite;
}

#riskStatus {
  height: calc(2.25rem + 15px);
  color: #0e3944;
  background-color: transparent;
  border: 2px solid #e1eaee;
}

#right_panel {
}

.sectionNode, .subSectionNode {
  min-height: 25px;
  max-height: 25px;
}

.rootNode {
  margin-bottom: 5px;
  min-height: 30px;
  max-height: 30px;
}

.sectionNode {
  margin-top: 5px;
}

.subSectionNode a span {
  color: #143d48;
  font-weight: 600;
}

.subSectionNode a span:first-child {
  color: #9db7bf;
}

.componentBARs {
  display: flex;
  font-size: 0.625rem;
  align-items: flex-end;
}

@media only screen and (max-width: 1024px) {
  .overviewStatusPanel {
    padding: 0px 5px;
    word-break: break-all;
  }
}

.risk_row {
  background: white; /* Show a solid color for older browsers */
  padding-left: 11px;
  margin: 0 0 3px 0;
  display: flex;
  box-shadow: 0px 1px 10px 0px rgb(244, 248, 250);
  transition: background-color 0.5s ease-in-out;
  cursor: pointer;
  /*flex-direction: column;*/
  /*@media (max-width: 450px) { flex-direction: column; }
  */
}
.popup-content {
  max-width: 375px;
  min-width: 150px;
  width: fit-content !important;
}

.MuiStepIcon1 {
  color: #1BA1C5;
}

.menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;

}
.menu-item {
  cursor: pointer;
  padding: 2px;
  height: 22px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-size: 12px;
}

.menu-item:hover {
  background-color :#2980b9;
  color: white;
}

.react-autosuggest__container input{
  border : 1px solid transparent;
  border-bottom-color: #ABC1C7;
}
.status_and_id {
  color:#ABC1C7;
  flex-basis: 70px;
  font-size: 0.7rem;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
}

.react-autosuggest__container input:focus {

  border : 1px solid transparent;
  border-bottom-color: #1BA1C5;
}

.risk_summary_form {
  color: #576A70;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@media only screen and (orientation: landscape) and (min-width: 800px) {
  .risk_row {
    flex-direction: row;
  }
}
.risk_summary_expanded {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  color: #576A70;
  font-weight: 500;
  white-space: normal !important;
  height: 67.2%;
  /*$font-size*$line-height*$lines-to-show;  Fallback for non-webkit */
  margin: 0 auto;

  width: 100%!important;
  line-height: 1.4;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.selected-row {
  /*border: solid 2px #1BA1C5;*/
  background: #f4f8fa;
}
.matrix-editor-wrapper {
  justify-content: space-around;
  width: 100%;
  display: flex;
}

.matrix-area {
  border-width: 4px;
  border-style: solid;
  border-color: #9F9A8D;
  padding-top: 1px;
}

.matrix-area-label {
  display: inline-flex;
}



.matrix-area-label:hover {
  background: #FEF9F9;
}

.matrix-row {
  display: flex;
  flex-wrap: nowrap;
}

.matrix-row:after {
  clear: both;
  content: "";
  display: table;
}

.matrix-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.matrix-column label {
  padding-left: 0px;
}

.matrix-editor-area-label:hover {
  background: #FEF9F9;
}

.matrix-editor-row {
  display: flex;
  flex-wrap: nowrap;
}

.matrix-editor-row:after {
  clear: both;
  content: "";
  display: table;
}

.matrix-editor-area {
  border-width: 8px;
  border-style: solid;
  border-color: #FB9281;
}

.matrix-editor-list-y, .matrix-editor-list-x {
  counter-reset: item;
  list-style: none;
}

.matrix-editor-list-y > li, .matrix-editor-list-x > li {
  list-style-type: none;
  counter-increment: item;
  margin-top: 5px;
}

.matrix-editor-list-y > li:before, .matrix-editor-list-x > li:before {
  display: inline-block;
  font-weight: bold;

}

.matrix-editor {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 0.5em;
}

.matrix-square-editor {
  background: #fff;
  border: 1px solid #8d9a9f;
  float: left;
  font-size: 10px;
  font-weight: bold;
  line-height: 22px;
  height: 27px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 2px;
  text-align: center;
  width: 27px;
}

.matrix-square-editor:focus {
  outline: none;
}



.matrix-square {
  background: #fff;
  border: 1px solid #8d9a9f;
  float: left;
  font-size: 10px;
  font-weight: bold;
  line-height: 10px;
  height: 10px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 2px;
  text-align: center;
  width: 10px;
}

.matrix-square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.form-group {
  margin-bottom: unset!important;
}

.maxrix-cell-element-tr {
  line-height: 8px;
}

.row_summary_caption_collapsed {
  color: #576A70;
  line-height: 16px;
  top: 0px;
  margin-top: 3px;
  padding-left: 5px;
  height: 100%;
  font-size: 0.95rem;
  font-weight: bold;
}

.row_summary_value_collapsed {
  color: #576A70;

  margin: auto;
  width: 50%;
  line-height: 12px;
  padding-left: 5px;
  height:50%;
}
/*$font-size: 16px;
$line-height: 1.4;
$lines-to-show: 3;
*/
.risk_summary {
  color: #576A70;

  font-weight: 500;
  /*display:inline-block;*/
  width: 400px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.matrix_border {
  height: 46px;
  width: 46px;
  opacity: 0.5;
  background-color: #FFFFFF;
}

.rectangle-copy {
  height: 6px;
  width: 6px;
  background-color: #FBD1CA;
}

.row_selected_background {
  height: 79px;
  background-color: #FEF9F9;
  box-shadow: inset 0 -2px 0 0 #FFFFFF;
}

.row_backgound {
  height: 79px;
  background-color: #FBFCFD;
  box-shadow: inset 0 -2px 0 0 #FFFFFF;
}

.captioncentral {
  margin: auto;
  width: 50%;
  white-space: nowrap;
  line-height: 12px;
  padding-left: 5px;
  height: 50%;
  color: #ABC1C7;
  /*font-family: "SF Pro Text";*/
  font-size: 0.80rem;
  font-weight: bold;
  line-height: 16px;
}

.measure_caption {

  color: #ABC1C7;
  /*font-family: "SF Pro Text";*/

  font-weight: 600;
  line-height: 16px;
  font-size: 0.8rem;
  padding-top : 5px;
}

.measure_status {
  line-height: 12px;
  width: 12px;
  height: 12px;
  font-size: 0.80rem;
  margin: 5px;
}

.measure_status_init {
  color: #576A70;
}

.measure_status_done {
  color: #7FCE3D;
}
.dots_row{
  padding-left: 0px;
}

.measure_status_in_progress {
  color: #1BA1C5;
}

.measure-row {
  padding-bottom: 15px;
}

.measure-header {
  display: flex;
  justify-content: flex-end;
  color: #ABC1C7;
  flex-basis: 70px;
  font-size: 0.7rem;
  position: absolute;
  right: 0;
  margin-right: 5px;
}


.measure-header .action_menu {
  margin-top: -20px;
}

.measure-seperator {
  min-height: 10px;
  display: block;
  background-color: #f4f8fa;
  width: calc(100% + 30px);
  margin: 15px 0px;
  margin-left: -15px;
}

.dotsmenu {
  width: 1.5625em;
}

.riskRowCaptionStyle  {
  flex:8;
  order:1;
  display: inline-flex;
}

.row_me {
  display: flex;
}

.dots_inline {

  right: 15px;
  position: absolute;
}


.form_container {
  background-color: #F4F8FA;
  padding-top: 6px;
  border: solid 2px #F4F8FA;
  padding-left: 12px;
  padding-right: 12px;
}

.form_container:focus, .form_container:focus-within {
  /*border: solid 2px #1BA1C5;*/
  outline: none;
}


.risk_row:hover {

  background: #FEF9F9;
  /*
  background: -moz-linear-gradient(#f9eded, #FEF9F9);
  background: -o-linear-gradient(#f9eded, #FEF9F9);
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#f9eded),
  to(#FEF9F9));
  background: -webkit-linear-gradient(#f9eded, #FEF9F9);

  animation-name: spin;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: linear; */
}



.form-control {
  border-radius: unset!important;
  border-top:none!important;
  border-left:none!important;
  border-right:none!important;
  border-width:2px!important;
  color: #576A70;

}

.matrix-area-label {
  font-size: 0.8rem;
  padding-left: 0px;
  padding-right: 0px;
  overflow: hidden
}

.matrixes-row {
  padding-left: 0px;
  padding-right: 0px;
  margin-right:0px;
  margin-left: 0px;
}

.matrix {
  font-size: 0.7rem
}

.risk_row_container.risk_row_tile .risk_row_container_two,
.risk_row_container.risk_row_tile .step_indent_container,
.risk_row_container.risk_row_tile .step_indent_container_two {
  padding-left: 0
}

.risk_row_container {
  display: flex;
  flex: 8;
  flex-direction: row;
  order: 2;
}

.risk_row_container_two {
  flex: 2;
  flex-direction: row;
  padding-left: 15px;
  display: flex;
}

.step_indent_container {
  flex: 4;
  flex-direction: column;
  padding-left: 15px;
}

.step_indent_container_two {
  flex: 2;
  flex-direction: row;
  padding-left: 15px;
  display: flex;
}

#two_matrixes {
  display: flex;
  flex-direction: row;
}

#textDescription {
  flex: 1;
  margin: 0px 0px 0px 5px;
}

.riskRowOverviewWrapper {
  flex: 1;
  line-height: 1.2;
  font-weight: 600;
  font-size: 0.9rem;
}

.riskRowActionMenuWrapper {
  /*flex-basis: 25px;
  display: block;
  position: initial;
  margin: -4px 0px 0px 20px;*/
}

.riskRowMeasureWrapper {
  display: flex;
  flex-direction: row;
  line-height: 2;
  font-size: 0.8rem;
  color: #adc3c9;
  justify-content: space-between;
  padding: 0px 8px 0px 0px;
  flex-wrap: wrap;
}



.form-control:focus {
  color: #464a4c;
  background-color: #fff;
  border-color: #1BA1C5;
  border-width:2px!important;
  border-bottom-color: #1BA1C5!important;
  border-top:none!important;
  border-left:none!important;
  border-right:none!important;
  outline: 0;
}

.col-form-label {
  color: #97B3BB;

}


.form-group {
  margin-bottom: unset!important;
}


.animated {
  -webkit-transition: height 0.2s;
  -moz-transition: height 0.2s;
  transition: height 0.2s;
}
.element-new {
  display: none;
  padding: 5px;
  background: #ffffff;
  border: 1px solid #919191;
  border-radius: 4px;
  float: left;
  margin-left: 35px;
  margin-bottom: 5px;
}

span.ui-button-text {
  padding: 5px 10px !important;
}

.ui-buttonset .ui-button {
  border:none;
  margin:0;
  background: #EBEBEB;
}

.zert-txted-toolbar .ui-buttonset :not(.ui-state-active).ui-button {
  background: #fff;
}



.fade-wait-leave {
  opacity: 1;
}
.fade-wait-leave.fade-wait-leave-active {
  opacity: 0;
  transition: opacity .4s ease-in;
}

.fade-wait-enter {
  opacity: 0;
}
.fade-wait-enter.fade-wait-enter-active {
  opacity: 1;
  /* Delay the enter animation until the leave completes */
  transition: opacity .4s ease-in .6s;
}

.fade-wait-height {
  transition: height .6s ease-in-out;
}

.cross-fade-leave {
  opacity: 1;
}
.cross-fade-leave.cross-fade-leave-active {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.cross-fade-enter {
  opacity: 0;
}
.cross-fade-enter.cross-fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.cross-fade-height {
  transition: height .5s ease-in-out;
}

.notranslate.public-DraftEditor-content:focus {
  border: 1px solid #1BA1C5;
}
.notranslate.public-DraftEditor-content {
  padding: 10px;
  margin: 15px 0px 0px 0px;
  background-color: #FFFFFF;
  border-radius: 2px;
  border: 1px solid transparent;
}
.risk_row:focus {
  /*border: 1px #ffb951 solid;*/
  border: none;
  outline: none;
}

.col-form-label {
  padding-left: 10px;
  font-size: 0.7900rem !important;
  color: #576A70 !important;
  font-weight: 600 !important;
  line-height: 1rem !important;
}
.col-sm-12 {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
}

.h3, h3 {
  font-size: 1.25rem !important;
  line-height: 2.4rem !important;
}

._edit__in__place__container_ {
  font-family: inherit !important;
  font-weight: 400;
  margin: 3px 0;
  color: #BF616A;
  font-size: 1.25rem;
}

._edit__in__place__container_ span input {
  width: 100%;
}

._edit__in__place__container_:hover {
  cursor: text;
}


.riskrow-select {
  border-radius: unset!important;
  border-top: none!important;
  border-left: none!important;
  border-right: none!important;
  border-width: 1px!important;
  color: #576A70;
}
.riskrow-select {
  display: block;
  width: 100%;
  padding: .5rem 0rem;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.riskrow-select:focus {
  color: #464a4c;
  background-color: #fff;
  border-color: #1BA1C5;
  border-width:2px!important;
  border-bottom-color: #1BA1C5!important;
  border-top:none!important;
  border-left:none!important;
  border-right:none!important;
  outline: 0;
}

.new_el_button {
  width: 16px;
  height: 16px;
  margin-top:7px;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 1024px) {
  .matrix-editor > div {
    padding-top: 10px;
  }
}

@media only screen and (max-width: 576px) {

  .form_container {
    padding-left: 6px;
    padding-right: 6px;
  }

  .matrix-column {
    justify-content: flex-start;
    padding-left: 15px;
    padding-bottom: 5px;
  }

  .matrix-column label {

  }

  .matrix-editor-wrapper > div {
    padding-top: 10px;
    padding-left: 10px;
  }

  .matrix-editor {
    flex-direction: column;
    display: flex;
  }
}

.menu-item {
  height: auto !important;
}

.show-enter {
  opacity: 0.01;
  width: 0px;
}

.show-enter.show-enter-active {
  opacity: 1;
  height: 100px;
  transition: 700ms;
}

._edit__in__place__container_ {
  font-family: inherit !important;
  font-weight: 400;
  margin: 3px 0;
  color: #BF616A;
  font-size: 1.25rem;
}

.show-leave {
  opacity: 1;
  height: 100px;
}

.show-leave.show-leave-active {
  opacity: 0.01;
  height: 0px;
  transition: 700ms;
}


.risk_row {
  background: white; /* Show a solid color for older browsers */
  padding-left: 11px;
  margin: 0 0 3px 0;
  display: flex;
  box-shadow: 0 1px 10px 0 rgb(244, 248, 250);
  transition: background-color 0.5s ease-in-out;
  cursor: pointer;
  /*flex-direction: column;*/
  /*@media (max-width: 450px) { flex-direction: column; }
  */
}
.popup-content {
  max-width: 375px;
  min-width: 150px;
  width: fit-content !important;
}

.MuiStepIcon1 {
  color: #1BA1C5;
}

.menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;

}
.menu-item {
  cursor: pointer;
  padding: 2px;
  height: 22px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-size: 12px;
}

.menu-item:hover {
  background-color :#2980b9;
  color: white;
}

.react-autosuggest__container input{
  border : 1px solid transparent;
  border-bottom-color: #E1EAEE;
}
.status_and_id {
  color:#ABC1C7;
  flex-basis: 70px;
  font-size: 0.7rem;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
}

.react-autosuggest__container input:focus {

  border : 1px solid transparent;
  border-bottom-color: #1BA1C5;
}

.risk_summary_form {
  color: #576A70;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@media only screen and (orientation: landscape) and (min-width: 800px) {
  .risk_row {
    flex-direction: row;
  }
}
.risk_summary_expanded {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  color: #576A70;
  font-weight: 500;
  white-space: normal !important;
  height: 67.2%;
  /*$font-size*$line-height*$lines-to-show;  Fallback for non-webkit */
  margin: 0 auto;

  width: 100%!important;
  line-height: 1.4;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.selected-row {
  /*border: solid 2px #1BA1C5;*/
  background: #f4f8fa;
}
.matrix-editor-wrapper {
  justify-content: space-around;
  width: 100%;
  display: flex;
}

.matrix-area {
  border-width: 4px;
  border-style: solid;
  border-color: #9F9A8D;
  padding-top: 1px;
}

.matrix-area-label {
  display: inline-flex;
}



.matrix-area-label:hover {
  background: #FEF9F9;
}

.matrix-row {
  display: flex;
  flex-wrap: nowrap;
}

.matrix-row:after {
  clear: both;
  content: "";
  display: table;
}

.matrix-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.matrix-column label {
  padding-left: 0;
}

.matrix-editor-area-label:hover {
  background: #FEF9F9;
}

.matrix-editor-row {
  display: flex;
  flex-wrap: nowrap;
}

.matrix-editor-row:after {
  clear: both;
  content: "";
  display: table;
}

.matrix-editor-area {
  border-width: 8px;
  border-style: solid;
  border-color: #FB9281;
}

.matrix-editor-list-y, .matrix-editor-list-x {
  counter-reset: item;
  list-style: none;
}

.matrix-editor-list-y > li, .matrix-editor-list-x > li {
  list-style-type: none;
  counter-increment: item;
  margin-top: 5px;
}

.matrix-editor-list-y > li:before, .matrix-editor-list-x > li:before {
  display: inline-block;
  font-weight: bold;

}

.matrix-editor {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 0.5em;
}

.matrix-square-editor {
  background: #fff;
  border: 1px solid #8d9a9f;
  float: left;
  font-size: 10px;
  font-weight: bold;
  line-height: 22px;
  height: 27px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 2px;
  text-align: center;
  width: 27px;
}

.matrix-square-editor:focus {
  outline: none;
}



.matrix-square {
  background: #fff;
  border: 1px solid #8d9a9f;
  float: left;
  font-size: 10px;
  font-weight: bold;
  line-height: 10px;
  height: 10px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 2px;
  text-align: center;
  width: 10px;
}

.matrix-square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.form-group {
  margin-bottom: unset!important;
}

.maxrix-cell-element-tr {
  line-height: 8px;
}

.row_summary_caption_collapsed {
  color: #576A70;
  line-height: 16px;
  top: 0px;
  margin-top: 3px;
  padding-left: 5px;
  height: 100%;
  font-size: 0.95rem;
  font-weight: bold;
}

.row_summary_value_collapsed {
  color: #576A70;
  margin: auto;
  width: 50%;
  line-height: 12px;
  padding-left: 5px;
  height:50%;
}

.risk_summary {
  color: #576A70;
  font-weight: 500;
  width: 400px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.matrix_border {
  height: 46px;
  width: 46px;
  opacity: 0.5;
  background-color: #FFFFFF;
}

.rectangle-copy {
  height: 6px;
  width: 6px;
  background-color: #FBD1CA;
}

.row_selected_background {
  height: 79px;
  background-color: #FEF9F9;
  box-shadow: inset 0 -2px 0 0 #FFFFFF;
}

.row_backgound {
  height: 79px;
  background-color: #FBFCFD;
  box-shadow: inset 0 -2px 0 0 #FFFFFF;
}

.captioncentral {
  margin: auto;
  width: 50%;
  white-space: nowrap;
  line-height: 12px;
  padding-left: 5px;
  height: 50%;
  color: #ABC1C7;
  /*font-family: "SF Pro Text";*/
  font-size: 0.80rem;
  font-weight: bold;
  line-height: 16px;
}

.measure_caption {
  color: #ABC1C7;
  /*font-family: "SF Pro Text";*/
  font-weight: 600;
  line-height: 16px;
  font-size: 0.8rem;
  padding-top : 5px;
}

.measure_status {
  line-height: 12px;
  width: 12px;
  height: 12px;
  font-size: 0.80rem;
  margin: 5px;
}

.measure_status_init {
  color: #576A70;
}

.measure_status_done {
  color: #7FCE3D;
}
.dots_row{
  padding-left: 0px;
}

.measure_status_in_progress {
  color: #1BA1C5;
}

.measure-row {
  padding-bottom: 15px;
}

.measure-header {
  display: flex;
  justify-content: flex-end;
  color: #ABC1C7;
  flex-basis: 70px;
  font-size: 0.7rem;
  position: absolute;
  right: 0;
  margin-right: 5px;
}

.measure-header .action_menu {
  margin-top: -20px;
}

.measure-seperator {
  min-height: 10px;
  display: block;
  background-color: #f4f8fa;
  width: calc(100% + 30px);
  margin: 15px 0px;
  margin-left: -15px;
}

.dotsmenu {
  width: 1.5625em;
}

.riskRowCaptionStyle  {
  flex:8;
  order:1;
  display: inline-flex;
}

.row_me {
  display: flex;
}

.dots_inline {
  right: 15px;
  position: absolute;
}

.form_container {
  background-color: #F4F8FA;
  padding-top: 6px;
  border: solid 2px #F4F8FA;
  padding-left: 12px;
  padding-right: 12px;
}

.form_container:focus, .form_container:focus-within {
  /*border: solid 2px #1BA1C5;*/
  outline: none;
}

.risk_row:hover {
  background: #FEF9F9;
  /*
  background: -moz-linear-gradient(#f9eded, #FEF9F9);
  background: -o-linear-gradient(#f9eded, #FEF9F9);
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#f9eded),
  to(#FEF9F9));
  background: -webkit-linear-gradient(#f9eded, #FEF9F9);

  animation-name: spin;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: linear; */
}

.form-control {
  border-radius: unset!important;
  border-top:none!important;
  border-left:none!important;
  border-right:none!important;
  border-width:2px!important;
  color: #576A70;

}

.matrix-area-label {
  font-size: 0.8rem;
  padding-left: 0px;
  padding-right: 0px;
  overflow: hidden
}

.matrixes-row {
  padding-left: 0px;
  padding-right: 0px;
  margin-right:0px;
  margin-left: 0px;
}

.matrix {
  font-size: 0.7rem
}

.risk_row_container {
  display: flex;
  flex: 8;
  flex-direction: row;
  order: 2;
}

.risk_row_container_two {
  flex: 2;
  flex-direction: row;
  padding-left: 15px;
  display: flex;
}

.step_indent_container {
  flex: 4;
  flex-direction: column;
  padding-left: 15px;
}

.step_indent_container_two {
  flex: 2;
  flex-direction: row;
  padding-left: 15px;
  display: flex;
}

#two_matrixes {
  display: flex;
  flex-direction: row;
}

#textDescription {
  flex: 1;
  margin: 0px 0px 0px 5px;
}

.riskRowOverviewWrapper {
  flex: 1;
  line-height: 1.2;
  font-weight: 600;
  font-size: 0.9rem;
}

.riskRowActionMenuWrapper {
  /*flex-basis: 25px;
  display: block;
  position: initial;
  margin: -4px 0px 0px 20px;*/
}

.riskRowMeasureWrapper {
  display: flex;
  flex-direction: row;
  line-height: 2;
  font-size: 0.8rem;
  color: #adc3c9;
  justify-content: space-between;
  padding: 0px 8px 0px 0px;
  flex-wrap: wrap;
}

.form-control:focus {
  color: #464a4c;
  background-color: #fff;
  border-color: #1BA1C5;
  border-width:2px!important;
  border-bottom-color: #1BA1C5!important;
  border-top:none!important;
  border-left:none!important;
  border-right:none!important;
  outline: 0;
}

.col-form-label {
  color: #97B3BB;
}

.form-group {
  margin-bottom: unset!important;
}

.animated {
  -webkit-transition: height 0.2s;
  -moz-transition: height 0.2s;
  transition: height 0.2s;
}
.element-new {
  display: none;
  padding: 5px;
  background: #ffffff;
  border: 1px solid #919191;
  border-radius: 4px;
  float: left;
  margin-left: 35px;
  margin-bottom: 5px;
}

span.ui-button-text {
  padding: 5px 10px !important;
}

.ui-buttonset .ui-button {
  border:none;
  margin:0;
  background: #EBEBEB;
}

.zert-txted-toolbar .ui-buttonset :not(.ui-state-active).ui-button {
  background: #fff;
}

.fade-wait-leave {
  opacity: 1;
}
.fade-wait-leave.fade-wait-leave-active {
  opacity: 0;
  transition: opacity .4s ease-in;
}
.fade-wait-enter {
  opacity: 0;
}
.fade-wait-enter.fade-wait-enter-active {
  opacity: 1;
  /* Delay the enter animation until the leave completes */
  transition: opacity .4s ease-in .6s;
}
.fade-wait-height {
  transition: height .6s ease-in-out;
}

.cross-fade-leave {
  opacity: 1;
}
.cross-fade-leave.cross-fade-leave-active {
  opacity: 0;
  transition: opacity 1s ease-in;
}
.cross-fade-enter {
  opacity: 0;
}
.cross-fade-enter.cross-fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in;
}
.cross-fade-height {
  transition: height .5s ease-in-out;
}

.notranslate.public-DraftEditor-content:focus {
  border: 1px solid #1BA1C5;
}
.notranslate.public-DraftEditor-content {
  padding: 10px;
  margin: 15px 0px 0px 0px;
  background-color: #FFFFFF;
  border-radius: 2px;
  border: 1px solid transparent;
}

.risk_row:focus {
  /*border: 1px #ffb951 solid;*/
  border: none;
  outline: none;
}

.col-form-label {
  padding-left: 10px;
  font-size: 0.7900rem !important;
  color: #576A70 !important;
  font-weight: 600 !important;
  line-height: 1rem !important;
}
.col-sm-12 {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
}

.h3, h3 {
  font-size: 1.25rem !important;
  line-height: 2.4rem !important;
}

._edit__in__place__container_ {
  font-family: inherit !important;
  font-weight: 400;
  margin: 3px 0;
  color: #BF616A;
  font-size: 1.25rem;
}

._edit__in__place__container_ span input {
  width: 100%;
}

._edit__in__place__container_:hover {
  cursor: text;
}

.riskrow-select {
  border-radius: unset!important;
  border-top: none!important;
  border-left: none!important;
  border-right: none!important;
  border-width: 1px!important;
  color: #576A70;
}
.riskrow-select {
  display: block;
  width: 100%;
  padding: .5rem 0rem;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ABC1C7;
  border-radius: .25rem;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.riskrow-select:focus {
  color: #464a4c;
  background-color: #fff;
  border-color: #1BA1C5;
  border-width:2px!important;
  border-bottom-color: #1BA1C5!important;
  border-top:none!important;
  border-left:none!important;
  border-right:none!important;
  outline: 0;
}

.new_el_button {
  width: 16px;
  height: 16px;
  margin-top:7px;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 1024px) {
  .matrix-editor > div {
    padding-top: 10px;
  }
}

@media only screen and (max-width: 576px) {

  .form_container {
    padding-left: 6px;
    padding-right: 6px;
  }

  .matrix-column {
    justify-content: flex-start;
    padding-left: 15px;
    padding-bottom: 5px;
  }

  .matrix-column label {

  }

  .matrix-editor-wrapper > div {
    padding-top: 10px;
    padding-left: 10px;
  }

  .matrix-editor {
    flex-direction: column;
    display: flex;
  }
}

.message-enter {
  opacity: 0.01;
  transform: scale(0.5) translateY(50%);
}
.message-enter-active {
  opacity: 1;
  transform: scale(1) translateY(0%);
  transition: all 300ms fade-in;
}
.message-exit {
  opacity: 1;
  transform: scale(1) translateY(0%);
}
.message-exit-active {
  opacity: 0.01;
  transform: scale(0.5) translateY(50%);
  transition: all 300ms fade-in;
}
.editParticipants {
  width: 100%;
  margin-right: 25px;
  padding-left: 16px;
}
.newEvent {
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  padding: 0;
  text-align: left;
  text-decoration: none;
  margin: 10px 16px 0px 25px;
  display: inline-flex;
  width: calc(100% - 50px);
  border-bottom: 2px solid #E1EAEE;
}

.react-datetime-picker__clock react-datetime-picker__clock--open {
  display : none !important;
}

.react-datetime-picker__button__icon {
  display : none !important;
}

.react-datetime-picker__clock {
  display : none !important;
}

.newEvent i {
  padding: 10px 15px 15px 1px;
  margin-bottom: 0px;
  color: #1BA1C5;
}

.newEvent span {
  width: 100%;
  color: #84A6AF;
  font-weight: 500;
  padding-top: 5px;
}
.eventCaption {
  padding: 10px 25px;
  display: inline-flex;
  width: 100%;
  font-size: 0.875rem;
}
.eventCaption span.eventDates {
  flex: 1;
  color: #ABC1C7;
  font-weight: 500;
  padding-top: 5px;
}
.eventCaption span.eventName {
  flex: 1;
  color: #ABC1C7;
  font-weight: 500;
  padding-top: 5px;
}
.eventCaption span.eventParticipants {
  flex: 1;
  color: #ABC1C7;
  font-weight: 500;
  padding-top: 5px;
}
.eventInfoRow {
  margin-left: 25px;
  display: inline-flex;

  box-shadow: inset 0 2px 0 0 #FFFFFF;
  width: calc(100% - 50px);
}
.eventInfoRow div.eventDates {
  flex: 1;
  color: #576A70;
  font-size: 1rem;
  padding: 25px 0px 0px 0px;
  display: flex;
  flex-direction: column;
}
.eventInfoRow div.eventName {
  flex: 1;
  color: #576A70;
  font-size: 1rem;
  font-weight: 600;
  padding: 25px 0px 0px 0px;
}
.eventInfoRow div.eventParticipants {
  flex: 1;
  color: #576A70;
  font-size: 1rem;
  padding: 25px 0px 0px 0px;

}
.editMember div.buttons {
  display: flex;
  padding: 45px 0px 0px 25px;
  justify-content: space-evenly;
}
.editMember .buttons .cheker {

  color: #84A6AF;
  font-size: 1rem;
}
.textButtonBlue {
  color: #1BA1C5;
  font-weight: 600;
  cursor: pointer;
  transition: transform .2s;
  border: none;
  background: none;
}
.textButtonRed {
  color: #EF4C31;
  font-weight: 600;
  cursor: pointer;
  transition: transform .2s;
  border: none;
  background: none;
}
.eventParticipants {
  margin: 0 10px 0 0;
  float: left
}

/*.eventParticipants :not(:last-child):after {
  content: ", "
}*/


.message-enter {
  opacity: 0.01;
  /* transform: scale(0.5) translateY(50%);*/
}
.message-enter-active {
  opacity: 1;
  /*transform: scale(1) translateY(0%);*/
  transition: all 300ms ease-in-out;
  transition-delay: 500ms;
}
.message-exit {
  opacity: 1;
  /* transform: scale(1) translateY(0%);*/
}
.message-exit-active {
  opacity: 0.01;
  /* transform: scale(0.5) translateY(50%);*/
  transition: all 300ms  ease-in-out;
}

.newTeamMember {
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  padding: 0;
  text-align: left;
  text-decoration: none;
  margin: 10px 16px 0px 25px;
  display: inline-flex;
  width: calc(100% - 50px);
  border-bottom: 2px solid #E1EAEE;
}

.fas.fa-plus-circle {
  padding: 10px 15px 15px 1px; margin-bottom: 0px; color: #1BA1C5;
}

.newTeamMember span{
  width: 100%; color: #84A6AF;font-weight: 500; padding-top: 5px;
}

.teamCaption {
  padding: 10px 25px; display: inline-flex; width: 100%; font-size: 0.875rem;
}
.teamCaption span.memberDetail{
  flex: 3; color: #ABC1C7; font-weight: 500; padding-top: 5px;
}
.teamCaption span.privileges{
  flex: 2; color: #ABC1C7; font-weight: 500; padding-top: 5px;
}
.teamCaption span.assigned{
  flex-basis: 100px; color: #ABC1C7;  font-weight: 500; padding-top: 5px;  display: var(--RRinfoDisplay);
}
.teamCaption span.completed{
  flex-basis: 100px; color: #ABC1C7; font-weight: 500; padding-top: 5px; display: var(--RRinfoDisplay); text-align: right;;
}

.memberInfoRow {
  margin-left: 25px; display: inline-flex;  height: 111px;	box-shadow: inset 0 2px 0 0 #FFFFFF; width: calc(100% - 50px);
}

.memberInfoRow div.memberDetail{
  flex: 3;
}
.memberInfoRow div.name{
  color: #576A70;	font-size: 16px;	font-weight: 600; padding: 19px 0px 0px 0px;
}

.memberInfoRow div.jobTitle{
  color: #97B3BB;font-size: 1rem;  padding: 10px 0px 0px 0px;
}

.memberInfoRow div.company{
  color: #97B3BB;font-size: 1rem;
}
.memberInfoRow div.privileges{
  flex: 2; color: #576A70; font-size: 1rem;  padding: 25px 0px 0px 0px;
}
.memberInfoRow div.privileges i{
  color: #C8DADE;	font-size: 16px; padding: 3px 10px 0px 0px;
}

.memberInfoRow div.progresColor1{
  flex-basis:33%; height:2px; background-color: #1BA1C5;
}
.memberInfoRow div.progresColor2{
  flex-basis:2px; height:2px; background-color: #FFFFFF; animation-name: completedAnime; animation-duration: 1s; animation-delay: 0.1s;
}
.memberInfoRow div.progresColor3{
  flex: auto; height:2px; background-color: #4CC58E;
}

.editMember {
  min-height: 240px;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: inset 0 -2px 0 0 #1BA1C5;
}

.editMember div.firstItem {
  height: 45px;
  display: flex;
  padding: 19px 0px 0px 25px;
}

.editMember .label{
  flex-basis: 100px;
  color: #84A6AF;
  font-size: 1rem;
  font-weight: 500;
}
.editMember .edit {
  flex: auto;
  margin-right: 25px;
  background-color: #FFFFFF;
}

.editMember .edit .rc-tree-select {
  box-shadow: inset 0 -2px 0 0 #E1EAEE;
}

.editMember .edit input:not(.rc-tree-select-search__field), .editMember .edit select, .editMember .edit textarea {
  box-shadow: inset 0 -2px 0 0 #E1EAEE;
  outline: none;
  background: none;
}

.editMember .edit input:focus:not(.rc-tree-select-search__field), .editMember .edit select:focus, .editMember .edit textarea:focus, .editMember .edit .rc-tree-select:focus,
.editMember .edit input:hover:not(.rc-tree-select-search__field), .editMember .edit select:hover, .editMember .edit textarea:hover, .editMember .edit .rc-tree-select:hover {
  box-shadow: inset 0 -2px 0 0 #1BA1C5;
  transition: all .25s ease;
}

.editMember .edit input {
  border: none; width: 100%;
}

.editMember div.item {
  height: 30px; display: flex; padding: 4px 0px 0px 25px; align-items: center;
}

.editMember .buttons .cheker{
  flex: auto; color: #84A6AF; font-size: 1rem;
}

.memberInfoHidable {
  flex-basis: 200px;
  display: var(--RRinfoDisplay);
}

[data-icon]:before {
  font-family: "zert" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "zert" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-lower:before {
  line-height: inherit;
  color: inherit !important;
  font-size: 1.5rem !important;
}

.work-area-header [class^='icon-']::before, [class*=' icon-']::before {
  font-size: 1.6em;
  padding-right: 5px;
  float: left;
  color: #FFFFFF;
  padding-top: 3px;
}

.several-elements-selected-panel [class^='icon-']::before, [class*=' icon-']::before {
  font-size: 1.6em;
  padding-right: 5px;
  float: left;
  color: #3b3f40;
}

.small-icon:hover {
  color: #46a8c9;
}

.small-icon {
  color: #02a2c6;
  transition: color 0.3s ease 0s;
}

.icon-a:before {
  content: "\e000";
}
.icon-action-plan:before {
  content: "\e001";
}
.icon-add:before {
  content: "\e002";
}
.icon-align-left:before {
  content: "\e003";
}
.icon-align-middle:before {
  content: "\e004";
}
.icon-align-right:before {
  content: "\e005";
}
.icon-approve:before {
  content: "\e006";
}
.icon-archive:before {
  content: "\e007";
}
.icon-arrow-down:before {
  content: "\e008";
}
.icon-arrow-up:before {
  content: "\e009";
}
.icon-author:before {
  content: "\e00a";
}
.icon-block:before {
  content: "\e00b";
}
.icon-calendar-month:before {
  content: "\e00c";
}
.icon-calendar-year:before {
  content: "\e00d";
}
.icon-catalog:before {
  content: "\e00e";
}
.icon-catalog-explorer:before {
  content: "\e00f";
}
.icon-checkin:before {
  content: "\e010";
}
.icon-checklist:before {
  content: "\e011";
}
.icon-checkout:before {
  content: "\e012";
}
.icon-chemical:before {
  content: "\e013";
}
.icon-company:before {
  content: "\e014";
}
.icon-copy:before {
  content: "\e015";
}
.icon-cut:before {
  content: "\e016";
}
.icon-delegate:before {
  content: "\e017";
}
.icon-delete:before {
  content: "\e018";
}
.icon-deviation:before {
  content: "\e019";
}
.icon-document:before {
  content: "\e01a";
}
.icon-dots:before {
  content: "\e01b";
}
.icon-download:before {
  content: "\e01c";
}
.icon-duplicate:before {
  content: "\e01d";
}
.icon-export:before {
  content: "\e01e";
}
.icon-filter:before {
  content: "\e01f";
}
.icon-folder:before {
  content: "\e020";
}
.icon-folder-explorer:before {
  content: "\e021";
}
.icon-folder-open:before {
  content: "\e022";
}
.icon-group:before {
  content: "\e023";
}
.icon-help-simple:before {
  content: "\e024";
}
.icon-image:before {
  content: "\e025";
}
.icon-import:before {
  content: "\e026";
}
.icon-index:before {
  content: "\e027";
}
.icon-industrial-project:before {
  content: "\e028";
}
.icon-info-collapse:before {
  content: "\e029";
}
.icon-info-expand:before {
  content: "\e02a";
}
.icon-inform:before {
  content: "\e02b";
}
.icon-list:before {
  content: "\e02c";
}
.icon-lock:before {
  content: "\e02d";
}
.icon-logout:before {
  content: "\e02e";
}
.icon-manage:before {
  content: "\e02f";
}
.icon-measure:before {
  content: "\e030";
}
.icon-more:before {
  content: "\e031";
}
.icon-new:before {
  content: "\e032";
}
.icon-new-project-planning:before {
  content: "\e033";
}
.icon-new-version:before {
  content: "\e034";
}
.icon-news:before {
  content: "\e035";
}
.icon-news-simple:before {
  content: "\e036";
}
.icon-old-project-element:before {
  content: "\e037";
}
.icon-paste:before {
  content: "\e038";
}
.icon-ped-object:before {
  content: "\e039";
}
.icon-phrase:before {
  content: "\e03a";
}
.icon-placement:before {
  content: "\e03b";
}
.icon-preview:before {
  content: "\e03c";
}
.icon-published-elements:before {
  content: "\e03d";
}
.icon-questionnaire:before {
  content: "\e03e";
}
.icon-redo:before {
  content: "\e03f";
}
.icon-refresh:before {
  content: "\e040";
}
.icon-remove:before {
  content: "\e041";
}
.icon-rename:before {
  content: "\e042";
}
.icon-report:before {
  content: "\e043";
}
.icon-review-binoculars:before {
  content: "\e044";
}
.icon-review-glasses:before {
  content: "\e045";
}
.icon-risk-assessment:before {
  content: "\e046";
}
.icon-risk-row:before {
  content: "\e047";
}
.icon-safety-inspection:before {
  content: "\e048";
}
.icon-save:before {
  content: "\e049";
}
.icon-search-folder:before {
  content: "\e04a";
}
.icon-search-view:before {
  content: "\e04b";
}
.icon-settings:before {
  content: "\e04c";
}
.icon-software-text:before {
  content: "\e04d";
}
.icon-spare-parts:before {
  content: "\e04e";
}
.icon-table:before {
  content: "\e04f";
}
.icon-task:before {
  content: "\e050";
}
.icon-template-gear:before {
  content: "\e051";
}
.icon-template-wrench:before {
  content: "\e052";
}
.icon-translation:before {
  content: "\e053";
}
.icon-translation-database:before {
  content: "\e054";
}
.icon-translation-job:before {
  content: "\e055";
}
.icon-undo:before {
  content: "\e056";
}
.icon-unlock:before {
  content: "\e057";
}
.icon-update:before {
  content: "\e058";
}
.icon-upload:before {
  content: "\e059";
}
.icon-user:before {
  content: "\e05a";
}
.icon-user-simple:before {
  content: "\e05b";
}
.icon-zoom-in:before {
  content: "\e05c";
}
.icon-zoom-out:before {
  content: "\e05d";
}
.icon-find:before {
  content: "\e05e";
}

.outer-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 12px;
  border-bottom: solid 1px rgba(27, 161, 197, .05);
  position: relative;
  transition: all .25s ease;
  cursor: pointer;
  text-decoration: none;
}

.outer-group.current {

}

.expand .outer-group {
  padding: 8px 20px;
}

.outer-group.expand {
  background-color: #05364A;
}

.profile-box {
  position: relative;
}

.profile-box .outer-group {
  z-index: 3;
}

.item-group {
  display: flex;
  align-items: center;
}

.side-bar .icon-box {
  width: 20px;
  height: 20px;
  text-align: center;
  margin-right: 20px;
  transition: all .25s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.outer-group span {
  font-size: 1rem;
  color: #ffffff;
  transition: all .25s ease;
}

.outer-group.current span {
  color: #1ba1c5 !important;
}

.outer-group label {
  margin-bottom: 0px;
}

.outer-group .icon-box svg path {
  fill: #235a69 !important;
  transition: all .25s ease;
}

.outer-group.current .icon-box svg path {
  fill: #1ba1c5 !important;
}


.outer-group.current .icon-box, .profile-box:active .icon-box {
  margin-right: 30px;
}
.outer-group:hover .icon-box, .profile-box:hover .icon-box {
  margin-right: 30px;
}



.profile-box-cage li {
  background-color: #ffffff14;
}

.profile-box .outer-group:hover .icon-box {
  margin-right: 40px;
}

.trigger {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  background-color: #ffffff14;
  border: none;
  outline: none;
}

.list {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: auto;
}

.circle-mark {
    display: flex;
    width: 10px;
    height: 10px;
}

.circle-half-left {
    width: 5px;
    height: 10px;
    border: solid 2px #C8DADE;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    background-color: transparent;
    border-right: none;
}

.circle-half-right {
    width: 5px;
    height: 10px;
    border: solid 2px #C8DADE;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    background-color: transparent;
    border-left: none;
}

.all-good .circle-half-left,
.all-good .circle-half-right {
    border-color: #1BA1C5;
}

.all-bad .circle-half-left,
.all-bad .circle-half-right {
    border-color: #FF8D48;
}

.all-fail .circle-half-left,
.all-fail .circle-half-right {
  border-color: #dc3545;
}

.all-done .circle-half-left,
.all-done .circle-half-right {
    border-color: rgb(8, 139, 8);
}

.half-good .circle-half-left,
.half-bad .circle-half-left {
    border-color: #1BA1C5;
}

.half-good .circle-half-right,
.half-bad .circle-half-right {
    border-color: #FF8D48;
}

.half-good .circle-half-left,
.half-fail .circle-half-left {
  border-color: #1BA1C5;
}

.half-good .circle-half-right,
.half-fail .circle-half-right {
  border-color: #dc3545;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


.ui.comments{margin:1.5em 0;max-width:650px}
.ui.comments:first-child{margin-top:0}
.ui.comments:last-child{margin-bottom:0}
.ui.comments .comment{position:relative;background:0 0;margin:.5em 0 0;padding:.5em 0 0;border:none;border-top:none;line-height:1.2}
.ui.comments .comment:first-child{margin-top:0;padding-top:0}
.ui.comments .comment .comments{margin:0 0 .5em .5em;padding:1em 0 1em 1em}
.ui.comments .comment .comments:before{position:absolute;top:0;left:0}
.ui.comments .comment .comments .comment{border:none;border-top:none;background:0 0}
.ui.comments .comment .avatar{display:block;width:2.5em;height:auto;float:left;margin:.2em 0 0}
.ui.comments .comment .avatar img,.ui.comments .comment img.avatar{display:block;margin:0 auto;width:100%;height:100%;border-radius:.25rem}
.ui.comments .comment>.content{display:block}.ui.comments .comment>.avatar~.content{margin-left:3.5em}
.ui.comments .comment .author{font-size:1em;color:rgba(0,0,0,.87);font-weight:700}
.ui.comments .comment a.author{cursor:pointer}
.ui.comments .comment a.author:hover{color:#1e70bf}
.ui.comments .comment .metadata{display:inline-block;margin-left:.5em;color:rgba(0,0,0,.4);font-size:1em}
.ui.comments .comment .metadata>*{display:inline-block;margin:0 .5em 0 0}
.ui.comments .comment .metadata>:last-child{margin-right:0}
.ui.comments .comment .text{margin:.25em 0 .5em;font-size:1em;word-wrap:break-word;color:rgba(0,0,0,.87);line-height:1.3}
.ui.comments .comment .actions{font-size:1em}
.ui.comments .comment .actions a{cursor:pointer;display:inline-block;margin:0 .75em 0 0;color:rgba(0,0,0,.4)}
.ui.comments .comment .actions a:last-child{margin-right:0}
.ui.comments .comment .actions a.active,.ui.comments .comment .actions a:hover{color:rgba(0,0,0,.8)}
.ui.comments>.reply.form{margin-top:1em}.ui.comments .comment .reply.form{width:100%;margin-top:1em}
.ui.comments .reply.form textarea{font-size:1em;height:12em; width: 100%}
.ui.collapsed.comments,.ui.comments .collapsed.comment,.ui.comments .collapsed.comments{display:none}
.ui.threaded.comments .comment .comments{margin:-1.5em 0 -1em 1.25em;padding:3em 0 2em 2.25em;-webkit-box-shadow:-1px 0 0 rgba(34,36,38,.15);box-shadow:-1px 0 0 rgba(34,36,38,.15)}
.ui.minimal.comments .comment .actions{opacity:0;position:absolute;top:0;right:0;left:auto;-webkit-transition:opacity .2s ease;transition:opacity .2s ease;-webkit-transition-delay:.1s;transition-delay:.1s}
.ui.minimal.comments .comment>.content:hover>.actions{opacity:1}
.ui.mini.comments{font-size:.58571429rem}
.ui.tiny.comments{font-size:.65714286rem}
.ui.small.comments{font-size:.72857143rem}
.ui.comments{font-size:1rem}
.ui.large.comments{font-size:.94285714rem}
.ui.big.comments{font-size:1.08571429rem}
.ui.huge.comments{font-size:1.22857143rem}
.ui.massive.comments{font-size:1.51428571rem}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}