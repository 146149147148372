.tree_root_context
  &__container
    padding: 10px
    display: grid
    grid-template-columns: 1fr
    grid-template-rows: 45px 45px 1fr
    grid-gap: 15px

  &__name-container
    display: grid
    grid-template-columns: 50px 1fr
