#catalog {
    flex-basis: 350px;
    background-color: #f4f8fa;
    overflow-x: scroll !important;
    padding-top: 10px;
    height: 100%;
}

#workingArea {
    flex: 1;
    overflow-y: scroll;
}

.catalog_caption {
    font-size: 1.7rem;
    color: #576a70;
    padding: 15px 0 0 25px;
}

.catalog_bread_crumbs {
    font-size: 0.8rem;
    color: #a5a5a5;
    padding: 0 0 0 25px;
}

.article_unit {
    cursor: pointer;
}

.catalog-objects-wrapper {
    padding: 0 20px;
}

#userPrivileges,
#catalogMetrics {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #f4f8fa;
    margin: 0 1em;
    padding: 1em;
}

.previleges_caption {
    color: #84a6af;
    font-size: 0.8rem;
    padding: 3px 0 0 0;
}

.previleges_item {
    display: flex;
    flex-direction: column;
    width: 170px;
    margin: 15px;
}

.previleges_item_name {
    color: #576a70;
    font-size: 1em;
    display: flex;
    flex-direction: column;
}

.previleges_item_name_username {
    color: #576a70;
    font-size: 0.8rem;
}

.metrics_item_caption {
    color: #576a70;
    font-size: 0.8rem;
}

.metrics_item {
    float: left;
    width: 170px;
    margin: 1em;
    cursor: pointer;
}

.metrics_graph_legend_left {
    float: left;
    color: #ccc;
    font-size: 0.6rem;
}

.metrics_graph_legend_right {
    float: right;
    color: #ccc;
    font-size: 0.6rem;
}

.catalog_rr_caption {
    color: #ef4c31;
    font-size: 10px;
    font-weight: bold;
    position: relative;
    top: 34px;
    left: 5px;
}

.catalog_rr_item {
    flex: 1;
    display: inline-block;
}

.catalog_rr_matrix {
    float: left;
    height: 46px;
    width: 46px;
    border: 2px solid #fbd1ca;
}

.catalog_rr_text {
    position: relative;
    top: -15px;
    left: 5px;
    color: #576a70;
    font-size: 13px;
    font-weight: 500;
    margin: 0 7px 0 0;
    overflow: hidden;
    height: 35px;
}

.progresColor1 {
    flex-basis: 33%;
    height: 2px;
    background-color: #1ba1c5;
}

.progresColor2 {
    flex-basis: 2px;
    height: 2px;
    background-color: #ffffff;
    animation-name: completedAnime;
    animation-duration: 1s;
    animation-delay: 0.1s;
}

.progresColor3 {
    flex: auto;
    height: 2px;
    background-color: #4cc58e;
}

h3 {
    color: #bf616a;
}



input:checked + label {
    background-color: #ffe6b0;
    border: 1px #ffb951 solid;
    opacity: 0.8;
    width: fit-content;
}

#reportParameters .cheker {
    padding-left: 4px;
}

.generate_report {
    margin: 0 25px;
    float: right;
}

.itemrow-wrapper {
    position: relative;
}



.itemrow {
    width: 100%;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
}

.itemrow:hover {
}

.itemrow .long_content {
    display: flex;
    position: relative;
    padding: 0 0 25px 0;
    width: 100%;
}

.item_content {
    background-color: #fce9be;
    display: flex;
}

.explorer-cage, .search-cage {
    height: 100%;
}

.item_content_selected {
    background-color: #f4f8fa;
    display: flex;
}

#itemrow .icon-box svg:hover path {
    fill: #ffb951 !important;
}

.hide_on_mobile {
    display: block;
}

.si_for_mobile {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.last_edit {
    color: #97b3bb;
    font-size: 1rem;
    font-weight: 600;
    padding: 12px 0 0 20px;
    overflow-y: hidden;
    transition: 1s;
}

.element_name {
    color: rgb(14, 57, 68);
    font-size: 1.2em;
    font-weight: 600;
    padding: 0;
    flex: 1;
}

.status_wrapper {
    display: flex;
    align-items: center;
}

.status_wrapper label {
    margin: 0px;
}

.task-action-button {
    float: right;
    padding-left: 10px;
    display: flex;
}

.last-modified-container .label-row label {
    margin-bottom: 0px;
}

.togglable-info-header {
    flex: 1;
    color: rgb(150, 179, 187);
    font-size: 0.8em;
}

.current-entry,
.past-entry {
    color: black;
    font-size: 0.8em;
}

.current-entry {
    font-weight: bold;
}

.explorer-cage, .search-cage {
    height: 100%;
}

@media (max-width: 600px) {
    .itemrow {
        flex-direction: column;
    }

    .catalog_for_mobile {
        flex-direction: column;
    }

    .si_for_mobile {
    }

    .hide_on_mobile {
        display: none;
    }

    .element_name {
        padding: 0px 10px 0px 0px;
    }

    .status_wrapper {
        flex-basis: 100%;
    }

    .status_wrapper label {
        margin-top: 5px;
    }

    .task-action-button {
        padding-left: 0px;
        margin-right: 0px;
    }
}

.catalog_for_mobile {
    display: flex;
    height: calc(100% - 56px);
}

@keyframes rotateAnime {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.rotateDelayed {
    animation-name: rotateAnime;
    animation-duration: 0.5s;
    animation-delay: 0.1s;
}

@keyframes zoomAnime {
    0% {
        zoom: 0.5;
    }
    33% {
        zoom: 1;
    }
    66% {
        zoom: 1.5;
    }
    100% {
        zoom: 1;
    }
}

.zoomDelayed {
    animation-name: zoomAnime;
    animation-duration: 0.5s;
    animation-delay: 0.1s;
}

@keyframes moveAnime {
    0% {
        position: relative;
        top: 0px;
    }
    20% {
        position: relative;
        top: 2px;
    }
    40% {
        position: relative;
        top: -2px;
    }
    60% {
        position: relative;
        top: 0px;
    }
    80% {
        position: relative;
        top: 2px;
    }
    100% {
        position: relative;
        top: 0px;
    }
}

.ul_menu {
    list-style-type: none;
    padding: 0;
    color: #1797c7;
}

.ul_menu li {
    display: inline-block;
    padding: 0 5px;
    overflow: hidden;
    white-space: nowrap;
    cursor: not-allowed;
}

.ul_menu li.menu_enabled:hover {
    background-color: #1799c7;
    color: white;
    cursor: pointer;
}

.ul_hided_menu {
    color: #1797c7;
    border: 1px solid #1794c7;
    width: fit-content;
    background-color: white;
    list-style-type: none;
    position: fixed;
    right: 10px;
    padding: 5px 15px;
    display: none;
}

.ul_hided_menu li {
    padding: 0 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: not-allowed;
}

.ul_hided_menu li.menu_enabled:hover {
    background-color: #1799c7;
    color: white;
    cursor: pointer;
}

.expand_info_caption {
    color: #97b3bb;
    font-size: 1rem;
    font-weight: 500;
    padding: 0 25px 26px 8px;
    flex: 1;
    overflow: hidden;
}

.expand-button-container {
    padding: 0px 5px;
    cursor: pointer;
    transition: 0.5s;
}

.expand-button-container svg {
    display: block;
}

.input_search::-webkit-input-placeholder {
    text-indent: 0px;
    transition: text-indent 0.3s ease;
    text-overflow: ellipsis;
    color: #ccc;
}

.input_search::-moz-placeholder {
    text-indent: 0px;
    transition: text-indent 0.3s ease;
    text-overflow: ellipsis;
    color: #ccc;
}

.input_search:-moz-placeholder {
    text-indent: 0px;
    transition: text-indent 0.3s ease;
    text-overflow: ellipsis;
    color: #ccc;
}

.input_search:-ms-input-placeholder {
    text-indent: 0px;
    transition: text-indent 0.3s ease;
    text-overflow: ellipsis;
    color: #ccc;
}

.input_search:focus::-webkit-input-placeholder {
    text-indent: 200px;
    transition: text-indent 0.3s ease;
}

.input_search:focus::-moz-placeholder {
    text-indent: 200px;
    transition: text-indent 0.3s ease;
}

.input_search:focus:-moz-placeholder {
    text-indent: 200px;
    transition: text-indent 0.3s ease;
}

.input_search:focus:-ms-input-placeholder {
    text-indent: 200px;
    transition: text-indent 0.3s ease;
}

@media (min-width: 576px) {
    .col-sm-xz {
        flex: 1;
    }
}
