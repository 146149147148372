.actionable-table
  &__container
    display: grid
    grid-template-columns: 1fr 50px
    height: 100%
    overflow: hidden

  &__table--container
    overflow: auto
    height: 100%

  &__control-panel-container
    display: flex
    justify-content: flex-start
    align-items: center
    align-content: center
    flex-flow: column nowrap

.table
  &__row
    &:hover
      cursor: pointer

    &--selected
      background: rgba(0, 0, 0, 0.07)


@media (max-width: 600px)
  .actionable-table
    &__container
      grid-template-columns: 1fr
      grid-template-rows: 50px 1fr

    &__control-panel-container
      order: -1
      flex-flow: row nowrap
      justify-content: center