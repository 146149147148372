.image-row {
	display: flex;
	margin-right: 10px;
	margin-left: 10px;
}

.image-box {
	overflow: hidden;
}

.image-box + .image-box {
	margin-left: 10px;
}

.row-image {
    width: 96px;
    height: 96px;
}