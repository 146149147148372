.captionLabel {
  height: 16px;
  color: #000000;
  font-size: 1rem;
  line-height: 16px;
  margin: 24px 0 0 0;
}

.main {
  height: 100%;
}

.note {
  width: 100%;
  box-sizing: border-box;
  display: block;
  max-width: 100%;
  line-height: 1.5;
  transition: box-shadow 0.5s ease;
  font-smoothing: subpixel-antialiased;
}

.catalogSelector {
  color: #0e3944;
  font-weight: 500;
  background: none;
  border: none;
  width: 100%;
  margin: 6px 0 0 -4px;
}

.checklistNewDescSubHeader {
  padding: 25px 0 10px 0;
  color: #0e3944;
  font-size: 1rem;
  font-weight: 300;
  line-height: 28px;
}
.checklistNewDescHeader {
  color: #4cc58e;
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: -0.2px;
  line-height: 36px;
}
.checklistNewDesc {
  box-shadow: inset 0 -2px 0 0 rgba(14, 57, 68, 0.05);
  vertical-align: middle;
  display: flex;
  align-items: center;
}
.checklistDesc {
  width: 100%;
  /*min-height: calc(100vh - 104px);*/
  padding: 24px;
  background-color: #ffffff;
  box-shadow: inset 0 1px 0 0 #f4f8fa, inset 1px 0 0 0 #eff3f6,
    inset -1px 0 0 0 #eff3f6, inset 0 -1px 0 0 #eff3f6, 0 8px 8px 0 #f4f8fa;
}

.checklistActivityList {
  margin: 0px 0px 10px 0px;
}

#right_panel {
  min-height: calc(var(--vh, 1vh) * 100 - 56px);
}

.createNewButtons {
  display: flex;
  padding: 20px 0;
  position: sticky;
  bottom: 0px;
}

#showChecklist,#contentChecklist{display:none;}
#showChecklist:checked~#contentChecklist{display:block;}

#showAdministration,#contentAdministration{display:none;}
#showAdministration:checked~#contentAdministration{display:block;}

#showProject,#contentProject{display:none;}
#showProject:checked~#contentProject{display:block;}

#showRiskAssessment,#contentRiskAssessment{display:none;}
#showRiskAssessment:checked~#contentRiskAssessment{display:block;}

#showDeviation,#contentDeviation{display:none;}
#showDeviation:checked~#contentDeviation{display:block;}

#showSI,#contentSI{display:none;}
#showSI:checked~#contentSI{display:block;}

.createNewButtons button:first-child {
  margin-right: 2em;
}

.required {
  color: #EF4C31;
}
.imgHelptext {
  max-width: 100%;
  padding-bottom: 10px;
}

@media screen and (max-width: 600px) {
  #right_panel {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }

  .createNewButtons {
    flex-direction: column;
    margin-left: -24px;
    align-items: center;
    justify-content: center;
  }

  .createNewButtons button:first-child {
    margin-bottom: 1em;
    margin-right: 0em;
  }

}
