.tabs {
	width: 100%;
	height: 56px;
	display: flex;
	align-items: center;
	padding: 0 15px;
	background-color: #F4F8FA;
	overflow-x: auto;
	position: sticky;
	top: 0;
	z-index: 1;
	box-shadow: inset 0 -2px 0 0 #FFFFFF;
}

.workfield .navi_bg{
	overflow-y: hidden;
}

.navi_bg {
    height: 56px;
	width: 100%;
	background-color: #F4F8FA;
	box-shadow: inset 0 -2px 0 0 #FFFFFF;
    display: var(--naviBarDisplay);
    position: sticky;
    top: 0;
    z-index: 1;
    overflow: auto;
	overflow-y: hidden;
}


.tabs ul {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	width: 100%;
}

.dummy {
	min-width: 10px;
}

.tabs li .btn::after {
	content: '';
	width: 4px;
	height: 4px;
	background-color: #EF4C31;
	right: -5px;
	top: 12px;
	border-radius: 50%;
	position: absolute;
	opacity: 0;
	transition: all .25s ease .25s;
}

.tabs li .btn.have-new::after {
	top: 2px;
	opacity: 1;
}

.tabs li button {
	overflow: visible;
	position: relative;
}

.tabs a, .tabs span {
	white-space: nowrap;
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: auto;
}

.tabs .btn, .tabs a {
	color: #0E3944;
	background-color: transparent;
	border-radius: 0;
	font-size: 0.9rem;
	padding: 6px 0;
	text-transform: uppercase;
}

.tabs .btn.inactive {
	opacity: .25;
}

.tabs .btn.inactive:hover {
	color: #0E3944;
	cursor: not-allowed;
}

.tabs .btn.active {
	border-bottom: solid 2px #1BA1C5;
}

.tabs .btn.active:hover {
	color: #0E3944;
}

.tabs .btn:hover, .tabs a:hover{
	color: #1BA1C5;
	background-color: transparent;
}

.tabs .btn:hover .bg-overlay {
	opacity: 0;
}

@media only screen and (max-width: 768px) {
	.tabs {
		overflow-x: auto;

	}
	.tabs::-webkit-scrollbar-thumb{
		background: inherit;
	}
}

#collapseBtn {
   display: none; width: 100%;
}

#expandBtn {
   display: block;  width: 100%;
}

.expandIco {
   font-size: 18pt; padding-left: 8px;
}


.leftPanelExpand {
   width: 100%;
   height: 100%;
   display: var(--leftpanelDisplayExpand);
}

.leftPanelExpandIco  {
   color: #ABC1C7;	font-size: 16px; padding: 17px 0px 0px 17px; cursor: pointer;
}


.resizeBtnMaxIco {
   margin-right: 13px; color: #1BA1C5; cursor: pointer; display: var(--RRinfoDisplayi);
}
.resizeBtnMinIco {
   margin-right: 13px; color: #1BA1C5; cursor: pointer; display: var(--RRinfoDisplay);
}
.resizeBtnCloseIco {
   margin-right: 18px; color: #1BA1C5; cursor: pointer;
}

.leftpanel_tab {
	overflow: auto;
	width: 100%;
	height: 100%;
	display: var(--leftpanelDisplay);
}
.team_tab {
    display: var(--teampanelDisplay);
}

.leftpanel {
  height: 100%;
  background-color: #FBFCFD;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  /*     Hate to do this but something is setting overflow to initial if view does not need to scroll to begin with    */
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.resizePanel {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.resizeLabel {
   color: #ABC1C7; 	font-size: 1rem;	font-weight: 500;	line-height: 16px; margin-right: 13px;
}

.topnav {	height: 32px;	width: 83px;	color: #0E3944;	font-size: 16px; flex: 1; line-height: 32px; padding-left: 25px; text-align: center;}

.topnav div {
  float: left;
  display: block;
  color: #0E3944;
  text-align: center;
  padding: 7px 5px 0px 5px;
  margin-right: 10px;
  text-decoration: none;
  font-size: 17px;
  border-bottom: 3px solid transparent;
}

.topnav div:hover {
  border-bottom: 3px solid #1BA1C5;
}

.topnav div.active {
  border-bottom: 3px solid #1BA1C5;
}
