
.dialog-edit__container {
    display: grid;
    grid-template-columns: 1fr;
}
.dialog-edit__name-container {

    display: grid;
    grid-template-columns: 50px 1fr;
}

.UserSuggestBox__Option {
    cursor: pointer;
    padding: 0 10px;
    margin-top: 2px;
    z-index: 2000;
}

.UserSuggestBox__Option:hover {
  background: rgba(0, 0, 0, 0.1);

}

.captionLabel {
     height: 16px;
     color: #abc1c7;
     font-size: 1rem;
     line-height: 16px;
     margin: 24px 0 0 0;
 }

.required {
    color: #EF4C31;
}