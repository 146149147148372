.intervalSelector {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.workfield {
    width: 100%;
    display:flex;
    height: 100%;
}
