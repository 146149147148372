.datepickerbutton {
  cursor: pointer;
  padding: 2px 6px;
  border: 2px solid #f07831;
  border-radius: 4px;
  background-color: transparent;
  font: inherit;
  color: #f07831; 
  font-size: 0.875rem;
    margin: 5px;
}

.react-datepicker__navigation-icon {
  top: -12px;
}

.react-datepicker__navigation--next:focus,
 .react-datepicker__navigation--previous:focus {
   outline: none;
 }
