.checklist-start {

}

.checklist-start.content-cage {
	/* height: calc(100vh - 56px); */
	/* overflow: visible; */
	overflow: hidden;
}

.checklist-start > .image-box {
	display: flex;
	justify-content: center;
	overflow: visible;
}

.checklist-start .worker-cage {
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 5;
	border-radius: 50%;
	width: 160px;
	height: 80px;
	/* margin-top: -80px; */
	margin: 1em auto;
}

.checklist-start > .content-group {
	padding: 0 20px 20px;
  height: calc(var(--vh, 1vh) * 100 - 56px - 56px);
	overflow: auto;
	display: block;
}

.checklist-start .title {
	font-size: 2rem;
	font-weight: normal;
	text-align: center;
	margin: 0;
}

.worker-cage img {
	height: auto;
	width: 42px;
}

.checklist-start .text-box {
	display: flex;
	flex-direction: column;
	max-width: 100%;
}

.group.justify-center label {
	margin-bottom: 0px;
}

.deadline-page {
	display: flex;
	width: 50%;
	padding: 0px 25px;
}

.deadline-page .text-box {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.deadline-page .text-box .edit, .deadline-page .text-box input {
	flex: auto;
	width: 100%;
	border: none;
	outline: none;
	box-shadow: inset 0 -2px 0 0 #E1EAEE;
}

.deadline-page .text-box .edit:first-child {
	margin-right: 25px;
}

.deadline-page .text-box .edit:hover, .deadline-page .text-box .edit:focus, .deadline-page .text-box input:hover, .deadline-page .text-box input:focus {
	box-shadow: inset 0 -2px 0 0 #1BA1C5;
	transition: all .25s ease;
}

@media only screen and (min-width: 769px) {
	.checklist-start.content-cage {
		padding: 0;
	}
	.checklist-start .worker-cage {
		width: 280px;
		height: 80px;
		/* margin-top: -100px; */
	}
	.worker-cage img {
		width: 60px;
	}
	.checklist-start .worker-cage {
		display: flex;
	}
}

@media only screen and (max-width: 1024px) {
	.deadline-page {
		width: 100%;
		padding: 0px 15px;
	}
	
	.deadline-page .text-box .edit:first-child {
		margin-right: 15px;
	}
}

.align-center-send-button {
    display: flex;
    justify-content: center;
}

.checklistInstruction {
    width: 100%;
    display: flex;
    justify-content: center;
}

.checklistDescContainer {
    width: 40%;
    margin-top: 5%;
    display: flex;
}

@media screen and (max-width: 1440px) {
    .checklistDescContainer {
        width: 60%;
    }
}

@media screen and (max-width: 1024px) {
    .checklistDescContainer {
        width: 95%;
    }
}