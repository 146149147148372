.workflowbuttons {
    flex: auto;
    display: flex;
    flex-direction: row;
}

.editMember .riskform {
    flex: auto;
    margin-right: 25px;
    /* box-shadow: inset 0 -2px 0 0 #E1EAEE; */
}

.editMember .riskform textarea:focus, .editMember .riskform textarea:hover {
  box-shadow: inset 0 -2px 0 0 #1BA1C5;
  transition: all .25s ease;
}

.editMember select {
  color: rgb(14, 57, 68);
  font-weight: 500;
  background: none;
  border: none;
  width: 100%;
  margin: 0px;
  box-shadow: inset 0 -2px 0 0 #E1EAEE;
  outline: none;
  padding: 0;
  height: initial !important;
}

.editMember select:hover {
  box-shadow: inset 0 -2px 0 0 #1BA1C5;
  transition: all .25s ease;
}

.editMember select:focus {
  box-shadow: inset 0 -2px 0 0 #1BA1C5;
  transition: all .25s ease;
  background: none;
}

.captionDeviation {
  color: #000000;
  font-size: 1rem;
  margin-bottom: 6px;
}

.button-wrapper {
    margin-top: 10vh;
    width: 100%;
    display: flex;
    justify-content: center;
}

.workflow-button {
    margin: 0 10px;
}

.auto-grow-input .text-placeholder {
  color: #000000;
}
