.row-wrapper{
  padding-top: 4px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  
}


.select-width {
	width: 100%;
}

.auto-grow-input {
	position: relative;
	width: 100%;
	overflow: hidden;
	font-size: 1rem;
}

.auto-grow-input textarea {
    overflow: hidden;
    resize: none !important;
    width: 100%;
    padding: 20px 0 10px;
    border: solid 1px transparent;
    font-size: 1rem;
    border-bottom-color: #ABC1C7;
    background: none;
    outline: none;
    color: #333333;
    transition: all .25s ease;
    min-height: 41px;
}

.no-underline {
    text-decoration: none !important;
    border-bottom: none !important;
}


.text-placeholder-select {
	color: #ABC1C7;
	font-size: 0.8rem;
}

.text-placeholder-readonly {
	color: #ABC1C7;
	font-size: 0.8rem;
}

.auto-grow-input .text-placeholder {
	color: #ABC1C7;
	font-size: 1rem;
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: -9px;
	transition: all .25s ease;
}

.auto-grow-input textarea::-webkit-input-placeholder {
	color: #ABC1C7;
}

.auto-grow-input textarea:focus {
	box-shadow: inset 0 -2px 0 0 #1BA1C5;
	/*border-bottom-color: #1BA1C5;
	border-width: 2px;*/
}

.auto-grow-input .line {
	position: absolute;
	left: -100%;
	bottom: 4px;
	width: 100%;
	height: 2px;
	background-color: #1BA1C5;
	transition: all .5s ease;
}

.auto-grow-input textarea:valid ~ .line {
	left: 0;
}

.auto-grow-input textarea:focus ~ .text-placeholder, .auto-grow-input textarea:valid ~ .text-placeholder {
	color: #ABC1C7;
	top: 0;
	margin-top: 0;
	font-size: 0.8rem;
}
